@import './../../assets/styles/variables.scss';

.logo {
  height: 57px;
  margin-left: -19px;
}

.top,
.bottomContent {
  margin: 10px 50px;
}

.logoWrapper {
  display: flex;
  flex-wrap: wrap;
}

.bottom {
  margin: 0px;
  height: 60px;
  background: $mainColor;
  display: flex;
  flex: 1;
  align-items: center;
}

.bottomContent {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.bottomContent a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
  padding-bottom: 4px;
  &:hover {
    color: #ccc;
    border-bottom-color: #ccc;
  }
}
