.container {
  padding: 10px 16px;
  border-radius: 10px;
  color: #004b87;
  max-width: 612px;
  width: 60%;
  display: block;
  text-align: inherit;
  cursor: pointer;
  margin: 15px 0;
  background-color: #fff;
  border: 1px solid #004b87;
}

.title {
  display: inline-block;
}
.icon {
  margin-left: 20px;
  font-size: 24px;
}
.content {
  white-space: pre-line;
}
.container:hover {
  -webkit-box-shadow: 8px 4px 15px -1px rgba(0, 0, 0, 0.52);
  box-shadow: 8px 4px 15px -1px rgba(0, 0, 0, 0.52);
}
.container:active {
  -webkit-box-shadow: -1px -4px 15px -1px rgba(0, 0, 0, 0.52);
  box-shadow: -1px -4px 15px -1px rgba(0, 0, 0, 0.52);
}
.aditionalInfo {
  display: flex;
  flex-direction: row-reverse;
  color: #172643;
}

.disable {
  color: #172643;
  cursor: unset;
  background-color: #e8e9ec;
}

.disable:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.disable:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
