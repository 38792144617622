.hus-epidemic-input-with-title-wrapper {
  display: flex;
  flex-direction: column;
}

.hus-epidemic-input-with-title-input {
  height: 16px;
  border: 1px solid #bcbcbc;
  padding: 10px;
  margin-right: 20px;
}

.hus-epidemic-input-with-title-label {
  font-size: 14px;
}

.hus-epidemic-input-with-title-required {
  font-weight: bold;
}

.hus-epidemic-input-with-title-required::before {
  content: '*';
  color: red;
}

.hus-epidemic-input-with-title-error {
  color: red;
  font-size: 16px;
  margin-bottom: 5px;
  background-color: #fff;
  border: none;
  text-align: left;
}
