.infoText {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.guideText {
  font-size: 16px;
  line-height: 19px;
}

.inputField {
  margin-bottom: 15px;
  width: 80%;
}

.buttonLogin {
  margin-top: 15px;
  background-color: #1b4a83;
  color: #ffffff;
  height: 40px;
  width: 77%;
  top: calc(50% - 40px/2);
}

.loginColumnContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  max-width: 1160px;
  background: #FFFFFF;
  border: 1px solid #E5EDF3;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.column1,
.column2 {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.column1 {
  margin-bottom: 70px;
  margin-left: 100px;
  transform: translateY(55px);
}

.column2 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 580px;
}

@media (min-width: 768px) {
  .loginColumnContainer {
    flex-wrap: nowrap;
  }

  .column1,
  .column2 {
    flex: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 40px;
    margin-right: 0px;
  }
}