@import './grid.scss';
@import './variables.scss';

body {
  font-family: 'Lato';
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  margin: 0px;
}

.App {
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
}

.content {
  background-color: $contentBackgroundColor;
}

.validation-error-text-wrapper {
  line-height: 14px;
}

.validation-error-text {
  color: red;
  font-size: 12px;
  font-weight: bold;
}

.white-link-inside-info a {
  color: #ffff;
}

h1 {
  font-family: 'Century Gothic';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  line-height: 37px;
  color: $mainColor;
}

h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.input-title {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}

.input-component {
  width: 20%;
}

.btnStyles {
  margin-top: 15px;
  padding: 10px 25px 10px 25px;
}

.info-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.guide-text {
  font-size: 16px;
  line-height: 19px;
}

.buttons-wrapper {
  min-height: 40px;
  margin-bottom: 30px;
  margin-top: 30px;
  flex-wrap: nowrap;
}

.confirm-btn,
.cancel-btn {
  min-height: 40px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 0;
}

.confirm-btn {
  background-color: #1b4a83;
  color: #ffffff;
}

.cancel-btn {
  background-color: #ffffff;
  color: #007e93;
  border: solid 1px #007e93;
}

@media (max-width: calc(#{$grid__bp-md * 1px})) {
  .input-component {
    width: 50%;
  }

  h1 {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: calc(#{$grid__bp-md * 1px})) {
  h2 {
    font-size: 18px;
  }

  .input-component {
    width: 60%;
  }
}

.input-component-wrapper {
  margin-bottom: 15px;
}
