.errorMessage {
  display: flex;
  color: #e20000;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
}

.errorIcon {
  margin-top: 3px;
  margin-right: 5px;
}

.errors ul {
  margin-top: 0px;
}
