.reminderTextWrapper {
  display: flex;
  align-items: center;
  background-color: #007e93;
  padding: 10px;
  border-radius: 6px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.remiderTextIcon {
  margin: 5px 20px 0 10px;
}

.remiderText {
  font-size: 18px;
}
