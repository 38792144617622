.contentWrapper {
  padding-top: 30px;
}

.title {
  padding-bottom: 10px;
}

.infoText {
  padding-bottom: 15px;
}
