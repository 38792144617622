@import '../../assets/styles/grid.scss';

.componentWrapper {
  display: flex;
  margin-top: 15px;
}

.title {
  font-size: 28px;
}

.hasRelatives {
  display: flex;
}

.radioGroup input {
  width: 25px;
  height: 25px;
}

.radioGroup label span {
  font-size: 16px;
}

.alcoholIconMiddle {
  display: flex;
  flex: 1;
}

.alcoholIconLeftWrapper {
  display: flex;
  flex: 5;
}

.alcoholIconRightWrapper {
  display: flex;
  flex: 5;
}

.alcoholIcons {
  display: flex;
  flex: 1;
  align-items: stretch;
}

.alcoholDetailsWrapper {
  display: flex;
  flex-direction: column;
  flex: 7;
  font-size: 16px;
  background-color: #fff;
  border: none;
}

div.alcoholIconWrapper {
  margin-top: 15px;
  display: flex;
  flex-wrap: nowrap;
}

.buttons {
  height: 40px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.confirmBtn,
.cancelBtn {
  height: 40px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 0;
}

.confirmBtn {
  background-color: #1b4a83;
  color: #ffffff;
}

.cancelBtn {
  background-color: #ffffff;
  color: #007e93;
  border: solid 1px #007e93;
}

.disabledLabel {
  color: #7d7d7d;
}

.number {
  margin-right: 15px;
}

@media (max-width: calc(#{$grid__bp-md * 1px})) {
  .alcoholIconWrapper {
    flex-direction: column;
  }
}
.subInputs div:first-child {
  padding-left: 30px;
  max-width: 100%;
}

.smokeContainer {
  display: inline-flex;
  @media (max-width: calc(#{$grid__bp-md * 1px})) {
    flex-direction: column;
  }
}
