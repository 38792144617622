.valueWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.valueTitle,
.value {
  font-size: 16px;
  line-height: 21px;
}

.valueTitle {
  font-weight: 700;
  margin-bottom: 3px;
}

.value {
  font-weight: 400;
}

.value ul {
  margin: 0px;
  padding-left: 20px;
}

.logoutBtn {
  font-size: 14px;
}
