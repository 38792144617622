.label,
.itemLabel {
  line-height: 22px;
  font-size: 14px;
}

.required {
  font-weight: bold;
}

.required::before {
  content: '*';
  color: red;
}

.item {
  margin-right: 15px;
}

fieldset {
  border: none;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
}

.fieldWrapper {
  display: flex;
  margin-bottom: 15px;
}

.errors {
  color: red;
  font-size: 16px;
}
fieldset:disabled legend,
fieldset:disabled span {
  color: #7d7d7d;
}

.errorContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  text-align: left;
}
