$mainColor: #004b87;
$altColor: #007e93;
$hoverColor: #527094;
$altHoverColor: #4d828b;
$contentBackgroundColor: #ffffff;
$fontColor: #000000;
$titleColor: #ffffff;
$titleFontFamily: 'Lato', sans-serif;
$announcementTitleBackgroudColor: #ff0000;
$announcementTitleColor: #ffffff;
$announcementContentColor: #000000;
$disabledBackgroundColor: #e8e8e8;
$disabledColor: #666;
$requiredColor: red;
