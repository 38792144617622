.language {
  background: none;
  border: none;
  padding: 0;
  color: #004b87;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
}

.langs {
  margin-right: 10px;
  text-align: right;
}
.baldLang {
  @extend .language;
  font-weight: bold;
}
