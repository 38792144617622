.registryDescription {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  margin-right: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
  .registryDescription {
    flex-direction: column; /* Display links vertically */
  }
}