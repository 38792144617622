.list {
  list-style: none;
  margin: 0 0 2px 40px;
}
.list::before {
  content: '\2022';
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.button {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  font-family: arial, sans-serif;
  color: rgb(218, 36, 36);
  cursor: pointer;
}

.errorLabel {
  display: inline-block;
  color: rgb(218, 36, 36);
  margin-left: 4px;
  background-color: #fff;
  border: none;
  text-align: left;
}
