.subtitle {
  font-family: 'Century Gothic Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #007e93;
}
.pLine {
  white-space: pre-line;
}
